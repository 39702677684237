import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Login',
      group: 0
    },
    component: () => import(/* webpackChunkName: "login" */ './pages/login/Login')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      title: 'Novo usuário',
      group: 0
    },
    component: () => import(/* webpackChunkName: "login" */ './pages/login/Register')
  },
  {
    path: '/new',
    name: 'new',
    meta: {
      title: 'Nova tarefa',
      group: 0
    },
    component: () => import(/* webpackChunkName: "login" */ './pages/tasks/NewTask')
  },
  {
    path: '/edit',
    name: 'edit',
    meta: {
      title: 'Editar tarefa',
      group: 0
    },
    component: () => import(/* webpackChunkName: "login" */ './pages/tasks/EditTask')
  },
  {
    path: '/',
    name: 'today',
    meta: {
      title: 'Tarefas de hoje',
      icon: 'fa fa-home',
      group: 1,
      bucket: 't'
    },
    component: () => import(/* webpackChunkName: "today" */ './pages/tasks/Today')
  },
  {
    path: '/tomorrow',
    name: 'tomorrow',
    meta: {
      title: 'Tarefas de amanhã',
      icon: 'far fa-caret-square-right',
      group: 1,
      bucket: 'p'
    },
    component: () => import(/* webpackChunkName: "tomorrow" */ './pages/tasks/Tomorrow')
  },
  {
    path: '/backlog',
    name: 'backlog',
    meta: {
      title: 'Arquivo de tarefas',
      icon: 'fas fa-archive',
      group: 1,
      bucket: 'b'
    },
    component: () => import(/* webpackChunkName: "backlog" */ './pages/tasks/Backlog')
  },
  {
    path: '/insights',
    name: 'insights',
    meta: {
      title: 'Gráficos legais',
      icon: 'fas fa-chart-line',
      group: 2
    },
    component: () => import(/* webpackChunkName: "insights" */ './pages/insights/Insights')
  },
  {
    path: '/configurations',
    name: 'configurations',
    meta: {
      title: 'Configurações',
      icon: 'fas fa-cogs',
      group: 2
    },
    component: () => import(/* webpackChunkName: "configurations" */ './pages/configurations/Configurations')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} - Jayio`

  const rotas = ['login', 'register']

  if (window.uid || rotas.includes(to.name)) {
    next()
  } else {
    next({ name: 'login' })
  }
})

export default router
