<template>
  <i class="fas fa-bars hamburguer-icon float-left" @click="showNavigation" v-if="visible"></i>
</template>

<script>
export default {
  data () {
    return {
      visible: false
    }
  },
  created () {
    this.$root.$on('Hamburguer::show', () => { this.visible = true })
    this.$root.$on('Hamburguer::hide', () => { this.visible = false })
  },
  methods: {
    showNavigation () {
      this.$root.$emit('Navigation::show')
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.hamburguer-icon {
  font-size: 1.2rem;
  margin-top: 8px;
  color: var(--dark-medium);
  &:hover {
    color: var(--featured);
    cursor: pointer;
  }
}
</style>
