<template>
  <button class="btn btn-logout" @click="logout">
    <i class="fa fa-power-off"></i>
    Sair
  </button>
</template>

<script>
export default {
  methods: {
    async logout () {
      this.$root.$emit('BaseSpinner::show')

      try {
        await this.$firebase.auth().signOut()
        this.$router.push({ name: 'login' })
      } catch (error) {
        this.$root.$emit('Notification::show', {
          message: error
        })
      } finally {
        this.$root.$emit('BaseSpinner::hide')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-logout {
  width: 100%;
  cursor: pointer;
  color: var(--white);
  &:hover {
    color: var(--featured);
  }
}
</style>
