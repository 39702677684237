<template>
  <div v-if="show" :class="`alert-${type}`" class="alert alert-dismissible fade show">
    {{ message }}
    <button type="button" class="close" @click="closeNotification">
      <span>&times;</span>
    </button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      type: 'danger',
      message: ''
    }
  },
  created () {
    this.$root.$on('Notification::show', payload => {
      this.show = true
      this.type = payload.type || 'danger'
      this.message = payload.message

      setTimeout(() => { this.closeNotification() }, payload.timeout || 4000)
    })
  },
  methods: {
    closeNotification () {
      this.show = false
      this.type = 'danger'
      this.message = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  position: absolute;
  z-index: 1000;
  right: 20px;
  top: 20px;
  max-width: 400px;
}
</style>
