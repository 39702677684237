<template>
  <nav class="nav flex-column justify-content-between">
    <div>
      <router-link
        :key="`g1-${i}`"
        v-for="(router, i) in routerLinks(1)"
        :to="{ name: router.name }"
        class="nav-link"
        exact
        exact-active-class="active"
      >
        <i :class="router.meta.icon"></i>
        {{ router.meta.title }}
      </router-link>
      <hr>
      <router-link
        :key="`g2-${i}`"
        v-for="(router, i) in routerLinks(2)"
        :to="{ name: router.name }"
        class="nav-link"
        exact
        exact-active-class="active"
      >
        <i :class="router.meta.icon"></i>
        {{ router.meta.title }}
      </router-link>
    </div>
    <div>
      <button-logout />
    </div>
  </nav>
</template>

<script>
import ButtonLogout from './ButtonLogout'

export default {
  components: { ButtonLogout },
  data () {
    return {
      showButtonNewTask: true
    }
  },
  methods: {
    routerLinks (group) {
      return this.$router.options.routes.filter(r => r.meta.group === group)
    }
  },
  watch: {
    $route (to, from) {
      this.showButtonNewTask = to.meta.group === 1
    }
  }
}
</script>

<style lang="scss" scoped>
.nav {
  margin-left: -15px;
  width: calc(100% + 30px);
  height: calc(100vh - 72px);
  .nav-link {
    color: var(--white);
    transition: .4s;
    &.active {
      color: var(--featured);
      background-color: transparent;
      border-left: 2px solid var(--featured);
    }
    &:hover {
      color: var(--dark);
      background-color: var(--featured);
    }
  }
  .configurations {
    text-align: center;
    a {
      width: 100%;
      cursor: pointer;
      color: var(--white);
      &:hover {
        color: var(--featured);
      }
    }
  }
}
</style>
