<template>
  <div id="app">
    <base-spinner/>
    <layout-notification/>
    <div class="container-fluid" v-if="isLogged">
      <div class="row">
        <div class="col-12 col-md-2 navigation-sidebar" v-if="showNavigation">
          <h1 class="navigation-title">jayio
            <button type="button" class="close" @click="hideNavigation">
              <span aria-hidden="true">&times;</span>
            </button>
          </h1>
          <layout-navigation/>
        </div>
        <div class="col app-content">
          <header-tile/>
          <router-view/>
        </div>
      </div>
    </div>

    <router-view v-else />
  </div>
</template>

<script>
import BaseSpinner from './components/global/BaseSpinner'
import HeaderTile from './components/layout/HeaderTile'
import LayoutNavigation from './components/layout/LayoutNavigation'
import LayoutNotification from './components/layout/LayoutNotification'

export default {
  components: { BaseSpinner, HeaderTile, LayoutNavigation, LayoutNotification },
  data () {
    return {
      isLogged: false,
      showNavigation: true
    }
  },
  created () {
    this.$root.$on('Navigation::show', () => { this.showNavigation = true })
    // this.$root.$on('Navigation::hide', () => { this.showNavigation = false })
  },
  mounted () {
    this.$firebase.auth().onAuthStateChanged(user => {
      window.uid = user ? user.uid : null

      this.isLogged = !!user
      this.$router.push({ name: window.uid ? 'today' : 'login' })
      this.$root.$emit('BaseSpinner::hide')
    })
  },
  methods: {
    hideNavigation () {
      this.$root.$emit('Hamburguer::show')
      this.showNavigation = false
    }
  }
}
</script>

<style lang="scss">
#app {
  min-height: 100vh;
  // background-image: url("assets/images/wallpapers/w4.jpg");
  // background-repeat: repeat;

  .navigation-sidebar {
    background-color: var(--dark-medium);
    .navigation-title {
      color: var(--light);
      font-size: 20pt;
      margin-top: 10px;
      margin-bottom: 30px;
      text-align: center;
      .close {
        color: var(--white);
        font-weight: normal;
        margin-top: 4px;
      }
    }
    hr {
      border-top: 1px solid rgb(0, 0, 0, 0.3);
      width: 90%;
      box-shadow: 1px 1px 2px #c0c0c0;
    }
  }

  .app-content {
    min-height: 100vh;
  }
}
</style>
