<template>
  <div class="row">
    <div class="col">
      <hamburguer />
    </div>
    <div class="col text-right">
      {{ email }}
    </div>
  </div>
</template>

<script>
import Hamburguer from '@/components/layout/Hamburguer.vue'

export default {
  components: {
    Hamburguer
  },
  data () {
    return {
      email: ''
    }
  },
  mounted () {
    this.$firebase.auth().onAuthStateChanged(user => {
      this.email = user.email
    })
  }
}
</script>

<style lang="scss" scoped>
.row {
  font-size: 0.9rem;
  border-bottom: 1px solid var(--light-medium);

  .col {
    line-height: 40px;
  }
}
</style>
